import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Input from "../../component/input/input";
import axios from "axios"
import useToastNotification from "../../utils/useToastNotification";
import { useState } from 'react';
import {NavLink, useLocation} from "react-router-dom"

const baseUrl = process.env.REACT_APP_BASE_URL

const Login = ({ formData, fieldsetClass = "", labelClass = "", inputClass = "", form_onchange_handler = () => null, setLoginShow, setUser }) => {
    const location = useLocation()
    const [notificationElement, setNotificationElement] = useState({
        loading: false,
        success: false,
        error: false,
        message: ""
    });

    const submit_login_fun = async () => {
        setNotificationElement({ ...notificationElement, loading: true })
        try {
            if (formData.loginEmail && formData.password) {
                const config = {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true
                }
                const res = await axios.post(`${baseUrl}/login`, { email: formData.loginEmail, password: formData.password }, config);                
                if (res.data.success) {
                    setUser({
                        auth: true,
                        user: res.data.user
                    })
                    setNotificationElement({ ...notificationElement, loading: false, success: true, message: res.data.message })
                }
            }
            else {
                setNotificationElement({ ...notificationElement, loading: false, error: true, message: "Email and Password field are required !" })
            };
        } catch (error) {
            setNotificationElement({ ...notificationElement, loading: false, error: true, message: error.response?.data?.message })
        }
    }

    // useSetTimeoutHelper({ setNotificationElement, notificationElement })
    useToastNotification({notificationElement, setNotificationElement})
    return (
        <div className=' p-0 pb-20 lg:pb-0 h-full mt-10 lg:h-[28rem]'>
            <ToastContainer />
            <h1 className="text-xl font-semibold w-full text-start">Welcome Back</h1>
            <small className="text-gray-400 block mt-1 w-full text-start">Welcome back! Please enter your details</small>

            <div className="  mt-7">

                <Input
                    fieldsetClass={fieldsetClass + " mb-3"}
                    labelClass={labelClass}
                    inputClass={inputClass}
                    labelName="Email"
                    labelFor="email"
                    inputType="email"
                    inputName="loginEmail"
                    inputValue=""
                    inputDefaultValue={formData.loginEmail || location.pathname.replace("/auth/", "")}
                    inputOnchange={form_onchange_handler}
                    placeholder="Enter your email..."
                />

                <Input
                    fieldsetClass={fieldsetClass + " mb-3"}
                    labelClass={labelClass}
                    inputClass={inputClass}
                    labelName="Password"
                    labelFor="password"
                    inputType="password"
                    inputName="password"
                    inputValue=""
                    inputDefaultValue={formData.password}
                    inputOnchange={form_onchange_handler}
                    placeholder="Enter your password..."
                    childData="password"
                />


                <div className="mb-3 flex flex-wrap content-center">
                    <input id="remember" type="checkbox" className="mr-1 checked:bg-teal-700" />{" "}
                    <label htmlFor="remember" className="mr-auto text-xs font-semibold">
                        Remember for 30 days
                    </label>
                    <NavLink to="/forget-password" className="text-xs font-semibold text-teal-700">
                        Forgot password?
                    </NavLink>
                </div>

                <div className="mb-3">
                    <button onClick={submit_login_fun} className="mb-1.5 block w-full text-center text-white bg-teal-700 hover:bg-teal-900 px-2 py-1.5 rounded-md" style={{ boxShadow: "-1px -1px 2px gray inset, 1px 1px 2px gray" }}>
                        Sign in
                    </button>
                    <button className="flex flex-wrap justify-center w-full border border-gray-300 hover:border-gray-500 px-2 py-1.5 rounded-md">
                        <img
                            className="w-5 mr-2"
                            src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                            alt="Google icon"
                        />
                        Sign in with Google
                    </button>
                </div>
            </div>
            <div className="text-center flex justify-center items-center gap-x-2 mt-0">
                <span className="text-xs text-gray-400 font-semibold">Don't have account?</span>
                <button onClick={() => setLoginShow(false)} className="text-xs font-semibold text-teal-700">
                    Sign up
                </button>
            </div>
        </div>
    )
}

export default Login;
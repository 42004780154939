import { memo } from "react";
import Profilecard from "../../component/profilecard";


const Profile = ({user}) => {
    return (
        <>
        <div className="min-h-full">

<div>
    <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex justify-between">
            <h1 className="text-2xl lg:text-3xl font-bold tracking-tight text-gray-900">Profile</h1>
        </div>
    </header>
    <main>
        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
           
           <Profilecard user={user}/>
            {/* <Table Pagination={<Pagination mailsHistory={mailsHistory} setMailsHistory={setMailsHistory} />} selctedData_fun={selctedData_fun} values={mailsHistory.mails} setFileName={setFileName} fileName={fileName} active={false} /> */}
        </div>
    </main>
</div>

</div>
        </>
    )
};

export default memo(Profile)
import { useEffect } from "react"
import { toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const useToastNotification = ({notificationElement, setNotificationElement}) => {
    useEffect(()=>{
        let id = null;
        let timeoutId = null;
        if(notificationElement.loading){
            id = toast.loading("Please wait...", {
                position : "top-right",
                autoClose : 4000
            })
        }
        
        if(notificationElement.success &&notificationElement. message && !notificationElement.error ){
            toast.success(notificationElement.message, {
                position : "top-right",
                autoClose : true,
                isLoading : false
            })
            timeoutId = setTimeout(()=>{
                setNotificationElement({...notificationElement, success : false, message : ""})
            },[4000])
        }
        else if(notificationElement.success){
            setNotificationElement({...notificationElement, loading : false})
            
            timeoutId = setTimeout(()=>{
                setNotificationElement({...notificationElement, success : false, message : ""})
            },[10])
        }

        
        if(notificationElement.error && notificationElement.message && !notificationElement.success){
            toast.error(notificationElement.message, {
                position : "top-right",
                autoClose : 4000,
                isLoading : false
            })
            timeoutId = setTimeout(()=>{
                setNotificationElement({...notificationElement, error : false, message : ""})
            },[4000])
        }
        else if(notificationElement.error){
            setNotificationElement({...notificationElement, loading : false})
            
            timeoutId = setTimeout(()=>{
                setNotificationElement({...notificationElement, success : false, message : ""})
            },[10])
        }

        return () => {
            if(id){
                toast.dismiss(id)
                clearTimeout(timeoutId)
            }
        }
    },[notificationElement.loading, notificationElement.success, notificationElement.message, notificationElement.error])
}

export default useToastNotification;
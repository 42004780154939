import { memo, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Table from "../../component/tableComponents/table";
import Papa from 'papaparse';


const VerifiedMails = ({ setUser, user, activeComponent, activeHeader, setWMails }) => {

    const location = useLocation();

    const [recipientsData, setRecipientsData] = useState([{
        name: "",
        email: "",
        address: "",
        password: "",
        // valid : false,
        selected: true
    }]);

    const [fileName, setFileName] = useState({
        recipientsFile: "",
        senderFile: "",
        search: ""
    })

    useEffect(() => {
        if (location.state) {
            let x = []
            location.state.mails.map(newMails => (x.push({ email: newMails.email, password: newMails.password, valid: newMails.valid, selected: true })))
            setRecipientsData(x)
        }
    }, [location.state])

    const selctedData_fun = async () => {

    }

    let workingMails = useMemo(()=>{
        return location.state.mails.filter(v=> v.valid)
    },[location.state])

    let nonWorkingMails = useMemo(()=>{
        return location.state.mails.filter(v=> v.valid === false)
    },[location.state])

    const nextProcess_fun = () => {
      }


const nextPage_fun = () => {
    
}

const downloadWorkingMails_fun = ({fileName="working_mails.csv"}) => {
    let workingMails2 = workingMails.map(({user, ...rest})=> { return rest })
    const csv = Papa.unparse(workingMails2);

    const blob = new Blob([csv], { type : "text/csv;charset-utf-8;"})

    const link = document.createElement('a');
    if (link.download !== undefined) {
        // Feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

const downloadNonWorkingMails_fun = ({fileName="non_working_mails.csv"}) => {
    let nonWorkingMails2 = nonWorkingMails.map(({user, ...rest})=> { return rest})
    const csv = Papa.unparse(nonWorkingMails2);

    const blob = new Blob([csv], { type : "text/csv;charset-utf-8;"})

    const link = document.createElement('a');
    if (link.download !== undefined) {
        // Feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

    return (
        <>
            <div className="min-h-full">

                <div>
                    <header className="bg-white shadow border">
                        <div className="mx-auto flex flex-col gap-y-4 md:flex-row justify-between max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text:text-xl lg:text-3xl font-bold tracking-tight text-gray-900">Tested Mails</h1>
                            <div className="flex justify-start gap-x-2 w-[100%] md:w-max">
                            <button onClick={downloadWorkingMails_fun} className=" px-3 text-teal-100 py-1 text-[12px] font-semibold bg-teal-500">Download Mails <span className="pl-1">{workingMails.length}</span></button>
                            <button onClick={downloadNonWorkingMails_fun} className=" px-3 text-red-100 py-1 text-[12px] font-semibold bg-red-500">Download Mails <span className="pl-1">{nonWorkingMails.length}</span></button>
                            </div>
                        </div>
                    </header>
                    <main>
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <Table nextProcess_fun={nextProcess_fun} nextPage_fun={nextPage_fun} selctedData_fun={selctedData_fun} values={recipientsData} setFileName={setFileName} fileName={fileName} active={false} />
                        </div>
                    </main>
                </div>

            </div>

        </>
    )
};

export default memo(VerifiedMails);


import { memo, useMemo, useState } from "react";


const Table = ({values=[], nextPage_fun=()=> null, selctedData_fun, setFileName, fileName, button=<></>, active=true, nextProcess_fun=()=> null, NextProcess_Btn=<></>, Pagination = <></>, downloadOrSelectHeader="Select", deleteOrEditHeader=<>Edit</>, deleteOrEditColumn=<>Edit</>, deleteLocalStorageMails_fun=()=>null}) => {
  const [notificationElement, setNotificationElement] = useState({
    loading: false,
    success: false,
    error: false,
    message: "",

    search : "",
    pageNo : 1,
    totalPages : 0,
    
    mails: [],
    filteredMailsLengthWithoutLimit:0,
    totalMailsLength : 0
});

    const tableBodyContent = useMemo(()=>{
      
      let filteredValues = fileName?.search ? values.filter(v=> v.email.includes(fileName.search)) : values

      return filteredValues?.map((v, i) => {
       return <tr key={i} className="transition-all hover:bg-gray-100 hover:shadow-lg">
          <td className="px-6 py-4 word-break">
            <div className="flex items-center justify-center">
              {/* <div className="text-sm font-medium text-gray-900 w-[90%] text-center">{ tab ==="MAILS SENT" ? v.info.envelope?.from : v?.email}</div> */}
              <div className="text-sm font-medium text-gray-900 w-[90%] text-center">{v?.email}</div>
            </div>
          </td>
         <td className="px-6 py-4 word-break">
            <div className="flex items-center justify-center">
              {/* <div className="text-sm font-medium text-gray-900 w-[90%] text-center">{ tab ==="MAILS SENT" ? v.info.envelope.to[0] : v?.name}</div> */}
              <div className="text-sm font-medium text-gray-900 w-[90%] text-center">{ v?.password}</div>
            </div>
          </td>

          <td className="px-3 lg:px-6 py-4 min-w-40">
            <span className="flex w-full justify-center items-center border text-xs font-semibold leading-5 text-green-800 rounded-full word-break">
              {v?.valid ? "Yes" : "No"}
            </span>
          </td>

          
          <td className="px-3 lg:px-6 py-4 text-sm text-center text-gray-500 min-w-40 word-break">
            {v?.sent}
          </td>
          <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap " style={{ placeContent: "center" }}>
            <div className="flex items-center justify-center ">
              {/* {v.selected === true ? "yes" : "no"} */}<input className="w-full flex" checked={v.selected} onChange={(e) =>{ selctedData_fun(i, e.target.checked)}} type="checkbox" name="selected" id="selected" />
              
            </div>
          </td>
          <td className="px-6 py-4 text-sm font-medium text-center whitespace-nowrap">
            <button className="text-indigo-600 hover:text-indigo-900 uppercase">
              {deleteOrEditColumn === "Delete" ? <button onClick={()=> deleteLocalStorageMails_fun(v.email)} className="text-[12px] px-2 py-1 text-red-600 uppercase">Delete</button> : <>Edit</>}
            </button>
          </td>
          
        </tr>
    } ) 
    },[values, fileName?.search])
    return (
        <>
        <div className="flex flex-col mt-6">
              {/* <h3 className="my-4 text-xl font-semibold text-gray-500 flex items-center">{tab} {button}</h3> */}
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b border-gray-200 rounded-md shadow-md">
                    <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                          Email
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                          Password
                          </th>
                          
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase text-center"
                          >
                          Working
                          </th>
                        
                         <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                          Mails Sent
                          </th>

                          <th
                            scope="col"
                            className="px-6 py-3 text-center text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            {downloadOrSelectHeader}
                          </th>
                          <th scope="col" className="relative z-0 px-6 py-3 text-center uppercase">
                            {deleteOrEditHeader}
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {tableBodyContent}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-2 h-12 mt-6">
                    {NextProcess_Btn}
                    {Pagination}
                </div>

            </div>
        </>
    )
};

export default memo(Table);
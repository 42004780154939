import axios from "axios";
import Input from "../../component/input/input"
import { useState } from "react";
import useToastNotification from "../../utils/useToastNotification";
import { ToastContainer } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL

const Signup = ({ formData, fieldsetClass = "", labelClass = "", inputClass = "", form_onchange_handler = () => null, setLoginShow }) => {

    const [notificationElement, setNotificationElement] = useState({
        loading: false,
        success: false,
        error: false,
        message: ""
    });

    const submit_signup_fun = async () => {
        setNotificationElement({ ...notificationElement, loading: true })
        try {
            if (formData.name && formData.signupEmail && formData.newPassword && formData.confirmPassword) {
                if (formData.newPassword === formData.confirmPassword) {
                    const config = {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        withCredentials: true
                    }
                    const res = await axios.post(`${baseUrl}/register`, { email: formData.signupEmail, name: formData.name, password: formData.newPassword, confirmPassword: formData.confirmPassword }, config);
                    if (res.data.success) {
                        setNotificationElement({ ...notificationElement, loading: false, success: true, message: res.data.message })
                    }
                    else {
                        setNotificationElement({ ...notificationElement, loading: false, error: true, message: res.data.message })
                    }
                }
                else {
                    setNotificationElement({ ...notificationElement, loading: false, error: true, message: "Password missmatch !" })
                }
            }
            else {
                setNotificationElement({ ...notificationElement, loading: false, error: true, message: "All fields are required !" })
            };
        } catch (error) {
            setNotificationElement({ ...notificationElement, loading: false, error: true, message: error.response.data.message })
        }
    }

    useToastNotification({notificationElement, setNotificationElement})

    return (
        <div className="px-6 lg:px-8 pt-16 lg:pt-8 py-8 h-[100vh] lg:h-[28rem]">
            <ToastContainer />
            <h1 className="text-xl font-semibold w-full text-start">Welcome back</h1>
            <small className="text-gray-400 block mt-1 w-full text-start">Welcome back! Please enter your details</small>

            <div className="mt-7 w-full flex flex-wrap justify-between gap-y-2">

                <Input
                    fieldsetClass={fieldsetClass + " w-full lg:w-[48%] mb-3"}
                    labelClass={labelClass}
                    inputClass={inputClass}
                    labelName="Name"
                    labelFor="name"
                    inputType="text"
                    inputName="name"
                    inputValue=""
                    inputDefaultValue={formData.name}
                    inputOnchange={form_onchange_handler}
                    placeholder="Enter your full name..."
                />

                <Input
                    fieldsetClass={fieldsetClass + " w-full lg:w-[48%] mb-3"}
                    labelClass={labelClass}
                    inputClass={inputClass}
                    labelName="Email"
                    labelFor="signupEmail"
                    inputType="email"
                    inputName="signupEmail"
                    inputValue=""
                    inputDefaultValue={formData.signupEmail}
                    inputOnchange={form_onchange_handler}
                    placeholder="Enter your email..."
                />

                <Input
                    fieldsetClass={fieldsetClass + " w-full lg:w-[48%] mb-0"}
                    labelClass={labelClass}
                    inputClass={inputClass}
                    labelName="New Password"
                    labelFor="password"
                    inputType="password"
                    inputName="newPassword"
                    inputValue=""
                    inputDefaultValue={formData.newPassword}
                    inputOnchange={form_onchange_handler}
                    placeholder="Enter your password..."
                    childData="newPassword"
                />

                <Input
                    fieldsetClass={fieldsetClass + " w-full lg:w-[48%] mb-3"}
                    labelClass={labelClass}
                    inputClass={inputClass}
                    labelName="Confirm Password"
                    labelFor="confirmPassword"
                    inputType="password"
                    inputName="confirmPassword"
                    inputValue=""
                    inputDefaultValue={formData.confirmPassword}
                    inputOnchange={form_onchange_handler}
                    placeholder="Re enter your password..."
                    childData="confirmPassword"
                />


                <div className="mb-3 flex w-full flex-wrap content-center">
                    <input id="remember" type="checkbox" className="mr-1 checked:bg-teal-700" />{" "}
                    <label htmlFor="remember" className="mr-auto text-xs font-semibold">
                        Remember for 30 days
                    </label>
                    {/* <button className="text-xs font-semibold text-teal-700">
                        Forgot password?
                    </button> */}
                </div>

                <div className="mb-3 w-full">
                    <button onClick={() => submit_signup_fun()} className="mb-1.5 block w-full text-center text-white bg-teal-700 hover:bg-teal-900 px-2 py-1.5 rounded-md" style={{ boxShadow: "-1px -1px 2px gray inset, 1px 1px 2px gray" }}>
                        Sign Up
                    </button>
                    {/* <button className="flex flex-wrap justify-center w-full border border-gray-300 hover:border-gray-500 px-2 py-1.5 rounded-md">
                        <img
                            className="w-5 mr-2"
                            src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                            alt="Google icon"
                        />
                        Sign in with Google
                    </button> */}
                </div>
            </div>
            <div className="text-center flex justify-center items-center gap-x-2">
                <span className="text-xs text-gray-400 font-semibold">Already have an account?</span>
                <button onClick={() => setLoginShow(true)} className="text-xs font-semibold text-teal-700">
                    Login
                </button>
            </div>
        </div>
    )
}

export default Signup
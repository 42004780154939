import { memo } from "react"


export const PasswordShow = ({id}) => {
    return (
        <div className="w-full flex justify-end py-2 px-1 items-end gap-x-3">
        <span className="text-[10px] font-semibold text-gray-600">Show Password</span>
        <input onChange={(e)=> e.target.checked  ? document.getElementById(id).type = "text" : document.getElementById(id).type = "password"} className="h-4 w-4" type="checkbox" name="" id="" />
    </div>
    )
}

const Input = ({fieldsetClass="", labelClass="",inputClass="", labelName="",labelFor="", inputType="text", inputName="", inputValue="", inputDefaultValue="", inputOnchange=()=> null, placeholder="", childData="" }) => {
    
    return (
        <>
        <fieldset className={fieldsetClass}>
                <label htmlFor={labelFor} className={labelClass}>{labelName}</label>
                <input
                // style={{boxShadow:" 0 0 1px gray inset, -1px -1px 3px gray inset"}}
                onChange={inputOnchange}
                defaultValue={inputDefaultValue}
                  type={inputType}
                  placeholder={placeholder}
                  className={inputClass}
                  id={childData}
                  name={inputName}
                />
               {childData && <PasswordShow id={childData}/>}
              </fieldset>
        </>
    )
}

export default memo(Input)
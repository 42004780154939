import { memo, useState } from "react";
import Login from "./login";
import Signup from "./signup";
import img from "./images/undraw_Mailbox_re_dvds.png"



const Auth = ({setUser}) => {
    const [loginShow, setLoginShow] = useState(true);

    const [formData, setFormData] = useState({
        name : "",
        loginEmail : "",
        signupEmail : "",
        password : "",
        newPassword : "",
        confirmPassword : "",
    })

    const fieldsetClass = "";
    const labelClass = "mb-2 block text-xs font-semibold w-full text-start px-1.5";
    const inputClass = "block w-full  outline-none text-gray-500 text-[12px] py-2 px-2 border-b";

    const form_onchange_handler = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name] : value
        })
    }


    return (
        <>
            <section className="flex flex-wrap min-h-screen w-full content-center justify-center bg-gray-100 py-0 lg:py-10">
                
                

                {/* Login component */}
                <div className="grid grid-cols-12 shadow-md h-[100vh] lg:h-max content-start">

                     {/* Login banner */}
                  { loginShow && <div className="order-1 flex items-center justify-center col-span-12 lg:col-span-6 content-center justify-center bg-teal-600 rounded-r-md h-[30vh] lg:h-full" style={{ width: "24rem", maxWidth: "100%" }}>
                        <img
                            className=" h-[100%] p-6 bg-center bg-no-repeat bg-cover object-contain rounded-r-md"
                            src={img}
                            alt="Login banner"
                        />
                    </div>}


                    {/* Login form */}
                    <div className={`order-2 flex flex-wrap ${ loginShow ? "col-span-12 lg:col-span-6" : "col-span-12" } content-start justify-center rounded-l-md bg-white `} style={{ width: `${loginShow ? "24rem" : "48rem"}`, maxWidth: "100%" }}>
                        <div className={`${ loginShow ? "w-72" : "w-full"} border-3 h-max`}>
                            {/* Heading */}
                            
                            {/* Form */}
                            {
                                loginShow ?
                                    <Login setUser={setUser} setFormData={setFormData} formData={{loginEmail : formData.loginEmail, password : formData.password }} fieldsetClass={fieldsetClass} labelClass={labelClass} inputClass={inputClass} form_onchange_handler={form_onchange_handler} setLoginShow={setLoginShow}/>
                                    :
                                    <Signup setFormData={setFormData} formData={{name : formData.name, signupEmail : formData.signupEmail, newPassword : formData.newPassword, confirmPassword : formData.confirmPassword }} fieldsetClass={fieldsetClass} labelClass={labelClass} inputClass={inputClass} form_onchange_handler={form_onchange_handler} setLoginShow={setLoginShow} />
                            }


                            
                        </div>
                    </div>

                </div>

            </section>

        </>
    )
};

export default memo(Auth)
import axios from "axios";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import Table from "../../component/tableComponents/table";
import Pagination from "../../component/pagination/pagination";
import { ClockLoader } from "react-spinners";
import Papa from 'papaparse';
import useToastNotification from "../../utils/useToastNotification";
import { ToastContainer } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

let config = {
    header: {
        "Content-Type": "Application/json"
    },
    withCredentials: true
}

const MailsHistory = ({ setUser, user, activeComponent, activeHeader }) => {

    let setLimitInput = useRef();
    let setDeleteMailsInput = useRef();
    const [mailsHistory, setMailsHistory] = useState({
        mails: [],
        totalMailsLength: 0,
        workinMailsLength: 0,
        workingSort: true,
        nonWorkingMailsLength: 0,
        success: false,
        loading: false,
        error: false,
        pageNo: 1,
        message: "",
        limit: 50,
        currentPage: 1,
        sentMails: []
    });
    const [fileName, setFileName] = useState({
        recipientsFile: "",
        senderFile: "",
        search: ""
    });

    const mailHistory_fun = async (limit = 0, workingSort = true) => {
        try {
            // setNotificationElement({ ...notificationElement, loading: true });
            setMailsHistory(prev => ({ ...prev, loading: true }))

            const res = await axios.get(`${baseUrl}/mails-history?limit=${limit || mailsHistory.limit}&pageNo=${mailsHistory.pageNo}&workingSort=${workingSort}`, config);



            if (res.data.success) {
                console.log("history ", res.data)
                await setMails_fun(res)
                // setMailsHistory(prev => ({ ...prev, loading: false, success: true, message: res.data.message, mails: res.data.mails, totalMailsLength: res.data.totalMailsLength, workinMailsLength: res.data.workinMailsLength, nonWorkingMailsLength: res.data.nonWorkingMailsLength }))
            }
        } catch (error) {
            setMailsHistory(prev => ({ ...prev, loading: false, error: true, message: error }))
        }
    }


    useEffect(() => {
        mailHistory_fun(mailsHistory.limit, mailsHistory.workingSort)
    }, [mailsHistory.pageNo])

    const selctedData_fun = async () => {

    }


    // async function setMails_fun(res) {
    //     let storedMails = localStorage.getItem("downloadedMails")

    //     if (storedMails) {

    //         let oldSaved = JSON.parse(storedMails);
    //         let modifiedArr = [];

    //         res.data.mails.map((v) => {
    //             oldSaved.forEach((savedMails, index) => {

    //                 if (v.email === savedMails) {
    //                     if (!modifiedArr.find(check => check.email === v.email)) {
    //                         modifiedArr.push({ ...v, selected: true })
    //                     }
    //                 }
    //                 else {
    //                     if (!modifiedArr.find(check => check.email === v.email)) {
    //                         modifiedArr.push({ ...v, selected: false })
    //                     }
    //                 }
    //             })
    //         });
    //         setMailsHistory(prev => ({ ...prev, loading: false, success: true, message: res.data.message, mails: modifiedArr, totalMailsLength: res.data.totalMailsLength, workinMailsLength: res.data.workinMailsLength, nonWorkingMailsLength: res.data.nonWorkingMailsLength }))
    //         return "null"

    //     }
    //     else {
    //         setMailsHistory(prev => ({ ...prev, loading: false, success: true, message: res.data.message, mails: res.data.mails, totalMailsLength: res.data.totalMailsLength, workinMailsLength: res.data.workinMailsLength, nonWorkingMailsLength: res.data.nonWorkingMailsLength }))
    //         return "null"

    //     }

    // }

    async function setMails_fun(res) {
        // Retrieve and parse stored emails from localStorage
        const storedMails = localStorage.getItem("downloadedMails");

        if (storedMails) {
            const oldSaved = JSON.parse(storedMails);
            const oldSavedSet = new Set(oldSaved); // Create a Set for quick lookup

            // Modify mails based on whether they exist in oldSavedSet
            const modifiedArr = res.data.mails.map(v => ({
                ...v,
                selected: oldSavedSet.has(v.email) // Set selected based on presence in oldSavedSet
            }));

            setMailsHistory(prev => ({
                ...prev,
                loading: false,
                success: true,
                message: res.data.message,
                mails: modifiedArr,
                totalMailsLength: res.data.totalMailsLength,
                workinMailsLength: res.data.workinMailsLength,
                nonWorkingMailsLength: res.data.nonWorkingMailsLength
            }));
        } else {
            // No stored mails, set state with the new data
            setMailsHistory(prev => ({
                ...prev,
                loading: false,
                success: true,
                message: res.data.message,
                mails: res.data.mails,
                totalMailsLength: res.data.totalMailsLength,
                workinMailsLength: res.data.workinMailsLength,
                nonWorkingMailsLength: res.data.nonWorkingMailsLength
            }));
        }

        return null;
    }



    async function downloadMailsAsCsv(data, fileName = "unknownFile") {
        let csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: "text/csv;charset-utf-8;" });

        let link = document.createElement("a");

        if (link.download !== undefined) {

            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)

        }

        return null
    }

    const downloadMails_fun = async (type = "working") => {
        try {
            setMailsHistory(prev => ({ ...prev, loading: true }))
            const res = await axios.get(`${baseUrl}/mails-download?type=${type}`, config);

            if (res.data.success) {

                await downloadMailsAsCsv(res.data.mails, type)

                setMailsHistory(prev => ({ ...prev, loading: false, success: true, message: res.data.message }))
            }
        } catch (error) {
            setMailsHistory(prev => ({ ...prev, loading: false, error: true, message: error }))
        }
    }

    const deleteMails_fun = async () => {
        try {
            if (setDeleteMailsInput.current.value <= mailsHistory.mails.filter(v => v.valid === false).length) {

                setMailsHistory(prev => ({ ...prev, loading: true }));

                let nonWorkingMailsId = mailsHistory.mails.filter(v => v.valid === false).map(v => v._id).slice(0, setDeleteMailsInput.current.value)

                nonWorkingMailsId = nonWorkingMailsId.join(",");

                const res = await axios.delete(`${baseUrl}/mails-delete?limit=${mailsHistory.limit}&pageNo=${mailsHistory.pageNo}&numberOfMails=${setDeleteMailsInput.current.value}&type=${false}&nonWorkingMailsId=${nonWorkingMailsId}`, config);

                if (res.data.success) {
                    await setMails_fun(res)
                    // setMailsHistory(prev => ({ ...prev, loading: false, success: true, message: res.data.message, mails: res.data.mails, totalMailsLength: res.data.totalMailsLength, workinMailsLength: res.data.workinMailsLength, nonWorkingMailsLength: res.data.nonWorkingMailsLength }))
                }

            }
            else {
                setMailsHistory(prev => ({ ...prev, loading: false, error: true, message: `Only ${mailsHistory.mails.filter(v => v.valid === false).length} non working mails are allowed to delete from this page !` }))
            }
        } catch (error) {
            setMailsHistory(prev => ({ ...prev, loading: false, error: true, message: error }))
        }
    }


    let setFetchMailsDetailsInput = useRef();


    const fetchMailsDetails_fun = async () => {
        try {
            if (setFetchMailsDetailsInput.current.value <= fetchMailsNo.length) {
                setMailsHistory(prev => ({ ...prev, loading: true }));

                // let workingMails = mailsHistory.mails.filter(v => v.valid === true).map(v => ({ email: v.email, password: v.password })).slice(0, setFetchMailsDetailsInput.current.value)
                let workingMails = fetchMailsNo.slice(0, setFetchMailsDetailsInput.current.value)

                const res = await axios.post(`${baseUrl}/mails-details?limit=${mailsHistory.limit}&pageNo=${mailsHistory.pageNo}&type=${false}`, workingMails, config);

                if (res.data.success) {
                    let uniqueArr = []
                    let nestedArr = res.data.sentMails.map(v => v.to)
                    nestedArr = nestedArr.flatMap(v => v)
                    nestedArr = nestedArr.map(v => ({ recipients: v }))

                    await downloadMailsAsCsv(nestedArr, "sentMailsDetails")
                    let flatArr = res.data.sentMails.map(v => v.from)
                    let latestStoredMails = localStorage.getItem("downloadedMails");

                    if (latestStoredMails) {
                        latestStoredMails = JSON.parse(latestStoredMails);
                        flatArr = [flatArr, latestStoredMails].flatMap(v => v)
                    }
                    uniqueArr = JSON.stringify(flatArr);
                    localStorage.setItem("downloadedMails", uniqueArr);

                    if (flatArr.length > 0) {
                        const oldSavedSet = new Set(flatArr); // Create a Set for quick lookup

                        // Modify mails based on whether they exist in oldSavedSet
                        const modifiedArr = mailsHistory.mails.map(v => ({
                            ...v,
                            selected: oldSavedSet.has(v.email) // Set selected based on presence in oldSavedSet
                        }));

                        setMailsHistory(prev => ({
                            ...prev,
                            loading: false,
                            success: true,
                            message: res.data.message,
                            mails: modifiedArr,
                            totalMailsLength: res.data.totalMailsLength,
                            workinMailsLength: res.data.workinMailsLength,
                            nonWorkingMailsLength: res.data.nonWorkingMailsLength
                        }));
                    }

                }
            }
            else {
                setMailsHistory(prev => ({ ...prev, loading: false, error: true, message: `Only ${fetchMailsNo.length} working mails are allowed to fetch details from this page !` }))
            }
        } catch (error) {
            setMailsHistory(prev => ({ ...prev, loading: false, error: true, message: error }))
        }
    }

    let fetchMailsNo = useMemo(() => {
        // Determine if there are any selected mails
        const hasSelectedMails = mailsHistory.mails.some(v => v.selected === true);

        // Filter valid mails
        let validMails = mailsHistory.mails.filter(v => v.valid === true);

        // If there are selected mails, filter out those from valid mails
        if (hasSelectedMails) {
            const selectedEmails = new Set(
                mailsHistory.mails.filter(v => v.selected === true).map(v => v.email)
            );

            validMails = validMails.filter(v => !selectedEmails.has(v.email));
        }

        return validMails;
    }, [mailsHistory.mails]);

    const deleteLocalStorageMails_fun = (type = "all") => {

        if (type === "all") {
            localStorage.removeItem("downloadedMails");
            mailHistory_fun(mailsHistory.limit, mailsHistory.workingSort)
        }
        else {
            let storeMails = localStorage.getItem('downloadedMails');
            if (storeMails) {
                storeMails = JSON.parse(storeMails);
                storeMails = storeMails.filter(v => v !== type);
                localStorage.setItem("downloadedMails", JSON.stringify(storeMails));
                mailHistory_fun(mailsHistory.limit, mailsHistory.workingSort)
            }
        }

    }

    const mailsSorting_fun = (workingSort=true) => {
        mailHistory_fun(mailsHistory.limit, workingSort)
    }

    useToastNotification({ notificationElement: mailsHistory, setNotificationElement: setMailsHistory })


    return (
        <>
            <div className="min-h-full">
                <ToastContainer />

                <div>
                    <header className="bg-white shadow sticky top-0 z-10 max-w-[100%]">
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex flex-wrap gap-y-3">
                            <h1 className="w-full flex items-center justify-between md:w-max text-2xl lg:text-3xl font-bold tracking-tight text-gray-900 flex items-center">
                                <span> Mails History</span>

                                {mailsHistory.loading === false &&
                                    <div className={`relative min-h-[36px] min-w-[90px] mr-2 flex md:hidden ${mailsHistory.workingSort ? "justify-start" : "justify-end"} shadow-sm overflow-hidden transition-2s`}>
                                        <input onChange={(e) =>{ 
                                            mailsSorting_fun(e.target.checked)
                                            setMailsHistory(prev => ({ ...prev, workingSort: e.target.checked }))
                                            }
                                            } className="absolute cursor-pointer z-10 w-full h-full opacity-0" type="checkbox" name="" id="" />

                                        <span className="relative text-[10px] min-w-[100%] flex items-center justify-center font-semibold text-teal-100">
                                            <span className="bg-teal-600 h-[80%] block w-[45%] flex items-center justify-center">
                                                Mails
                                            </span>
                                            <span className="bg-white h-[80%] block w-[45%] flex items-center justify-center text-teal-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                </svg>
                                            </span>
                                        </span>

                                        <span className="relative text-[10px] min-w-[100%] flex items-center justify-center font-semibold text-teal-100">
                                            <span className="bg-red-600 h-[80%] block w-[45%] flex items-center justify-center">
                                                Mails
                                            </span>
                                            <span className="bg-white h-[80%] block w-[45%] flex items-center justify-center text-red-600">
                                                <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                </svg>
                                            </span>
                                        </span>

                                    </div>
                                }
                            </h1>





                            <div className="w-max max-w-[100%] h-full min-h-[36px] flex items-center justify-center flex-wrap ml-auto mr-2">
                                {mailsHistory.loading ?
                                    <ClockLoader color="green" className="mr-16 ml-2" size="20" />
                                    : <>
                                        <div className={`relative min-h-[36px] min-w-[90px] mr-2 hidden md:flex ${mailsHistory.workingSort ? "justify-start" : "justify-end"} shadow-sm overflow-hidden transition-2s`}>
                                            <input defaultChecked={mailsHistory.workingSort} onChange={(e) => {
                                                mailsSorting_fun(e.target.checked)
                                                setMailsHistory(prev => ({ ...prev, workingSort: e.target.checked }))
                                            }} className="absolute cursor-pointer z-10 w-full h-full opacity-0" type="checkbox" name="" id="" />

                                            <span className="relative text-[10px] min-w-[100%] flex items-center justify-center font-semibold text-teal-100">
                                                <span className="bg-teal-600 h-[80%] block w-[45%] flex items-center justify-center">
                                                    Mails
                                                </span>
                                                <span className="bg-white h-[80%] block w-[45%] flex items-center justify-center text-teal-600">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="size-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                    </svg>
                                                </span>
                                            </span>

                                            <span className="relative text-[10px] min-w-[100%] flex items-center justify-center font-semibold text-teal-100">
                                                <span className="bg-red-600 h-[80%] block w-[45%] flex items-center justify-center">
                                                    Mails
                                                </span>
                                                <span className="bg-white h-[80%] block w-[45%] flex items-center justify-center text-red-600">
                                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </span>
                                            </span>

                                        </div>

                                        <div className=" max-w-screen flex flex-row justify-start overflow-x-auto">
                                            <input ref={setLimitInput} id="limitSet_Input" type="number" defaultValue={mailsHistory.limit} className="text-[12px] min-h-[36px] block w-[60px] text-gray-500 text-center outline-0 shadow-sm rounded-md" />

                                            <button disabled={mailsHistory.loading ? true : false} onClick={
                                                (e) => {
                                                    e.preventDefault()
                                                    mailHistory_fun(setLimitInput.current.value);
                                                    setMailsHistory(prev => ({ ...prev, limit: setLimitInput.current.value || mailsHistory.limit, pageNo: 1 }))
                                                }
                                                // () => setLimitInput.current.value < (mailsHistory.totalMailsLength) && setMailsHistory(prev => ({ ...prev, limit: setLimitInput.current.value || mailsHistory.limit, pageNo: 1 }))
                                            } className="whitespace-nowrap w-max px-3 h-full min-h-[36px] text-[12px] text-gray-100 text-center outline-0 bg-gray-800">Set Page</button>

                                            <input ref={setDeleteMailsInput} id="deleteSet_Input" type="number" defaultValue={mailsHistory.mails.filter(v => v.valid === false).length} className="text-[12px] min-h-[36px] block w-[60px] text-gray-500 text-center outline-0 shadow-sm rounded-md" />

                                            <button type="button" disabled={mailsHistory.loading ? true : false} onClick={(e) => {
                                                e.preventDefault()
                                                deleteMails_fun()
                                            }} className="whitespace-nowrap w-max px-3 h-full min-h-[36px] text-[12px] text-red-100 text-center outline-0 bg-red-700">Delete Mails</button>

                                            <input ref={setFetchMailsDetailsInput} id="fetchMailsSet_Input" type="number" defaultValue={fetchMailsNo.length} className="text-[12px] min-h-[36px] block w-[60px] text-gray-500 text-center outline-0 shadow-sm rounded-md" />

                                            <button disabled={mailsHistory.loading ? true : false} onClick={() => fetchMailsDetails_fun()} className="whitespace-nowrap w-max px-3 h-full min-h-[36px] text-[12px] text-gray-100 text-center outline-0 bg-gray-800">Fetch Mails</button>

                                        </div>
                                    </>
                                }
                            </div>

                            {mailsHistory.totalMailsLength > 0 &&
                                <div className="flex justify-start gap-x-2 w-[100%] md:w-max ">
                                    <button onClick={() => downloadMails_fun("working")} className="whitespace-nowrap px-3 text-teal-100 py-1 text-[12px] font-semibold bg-teal-500">Download Mails {mailsHistory.workinMailsLength}</button>
                                    <button onClick={() => downloadMails_fun("nonWorking")} className="whitespace-nowrap px-3 text-red-100 py-1 text-[12px] font-semibold bg-red-500">Download Mails {mailsHistory.nonWorkingMailsLength}</button>
                                </div>}
                        </div>
                    </header>
                    <main>
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <Table Pagination={<Pagination mailsHistory={mailsHistory} setMailsHistory={setMailsHistory} />} selctedData_fun={selctedData_fun} values={mailsHistory.mails} setFileName={setFileName} fileName={fileName} active={false} downloadOrSelectHeader="Download"
                                deleteOrEditHeader={
                                    <button onClick={() => deleteLocalStorageMails_fun("all")} className="text-[12px] bg-red-500 px-3 py-1 text-red-100 uppercase">Delete All</button>
                                }
                                deleteOrEditColumn="Delete"
                                deleteLocalStorageMails_fun={deleteLocalStorageMails_fun}
                            />
                        </div>
                    </main>
                </div>

            </div>
        </>
    )
};

export default memo(MailsHistory)
import Dashboard from "../page/dashboard/dashboard";

const Layout = ({setUser, user, ActiveComponent, activeHeader}) => {
    return (
        <>
            <Dashboard setUser={setUser} user={user} ActiveComponent={ActiveComponent} activeHeader={activeHeader}/>
        </>
    )
}

export default Layout;
import { memo, useMemo } from "react";
import {ClockLoader} from "react-spinners"

const Pagination = ({ mailsHistory, setMailsHistory }) => {

    const totalPages = useMemo(() => {

        if (mailsHistory.totalMailsLength > 0) {
            let totalPages = Math.ceil(mailsHistory.totalMailsLength / mailsHistory.limit)
            return totalPages
        }
    }, [mailsHistory])

    const paginationBtns = useMemo(()=>{
       return Array.from({length : totalPages}).map((v,i)=>{
        return <button key={i} disabled={mailsHistory.loading ? true : false} onClick={()=> paginationBtns_fun({type:"number", value:i+1})} className={`${mailsHistory.pageNo === i+1 ? "bg-gray-100" : "bg-white"} px-3 py-1 cursor-pointer hover:bg-gray-100 shadow-sm text-[12px] text-gray-500 font-semibold bg-gray-100`}>{i+1}</button>     
        })
    },[totalPages, mailsHistory.pageNo, mailsHistory.loading])

    const paginationBtns_fun = ({type="number", value=1}) => {
        if(type==="number"){
            setMailsHistory(prev=> ({...prev, pageNo : value}))
        }
        if(type === "prev" && mailsHistory.pageNo > 1){
            setMailsHistory(prev=> ({...prev, pageNo : prev.pageNo-1}))
        }
        if(type === "next" && totalPages > mailsHistory.pageNo){
            setMailsHistory(prev=> ({...prev, pageNo : prev.pageNo+1}))
        }
    }

    

    return (
        <>
            <div className="flex w-full py-3 px-6 md:px-8 justify-end">
                
              {mailsHistory.loading ? <ClockLoader color="green" className="mr-16" size="20" /> :
               <>
                <div className=" text-gray-400 text-[12px] font-bold h-full block px-3 py-1 mr-2">Total Mails : {mailsHistory.totalMailsLength}</div>
               
                <button disabled={mailsHistory.loading ? true : false} onClick={()=>paginationBtns_fun({type:"prev", value:"-"})} className={`${mailsHistory.pageNo > 1 ? "bg-white" : "bg-gray-100"} mr-1 px-3 py-1 cursor-pointer hover:bg-gray-100 shadow-sm text-[12px] text-gray-500 font-semibold`} >Prev</button>
                <ul className="flex">
                    {paginationBtns}
                </ul>
                <button disabled={(mailsHistory.loading || mailsHistory.pageNo >= totalPages)  ? true : false} onClick={()=>paginationBtns_fun({type:"next", value:"-"})} className={`${totalPages > mailsHistory.pageNo ? "bg-white" : "bg-gray-100"} ml-1 px-3 py-1 cursor-pointer hover:bg-gray-100 shadow-sm text-[12px] text-gray-500 font-semibold`} >Next</button>
                </>
                }
            </div>
        </>
    )
}

export default memo(Pagination)
import Papa from 'papaparse';
import { memo, useState } from 'react';
import Table from './tableComponents/table';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import useToastNotification from "../utils/useToastNotification";
import { ToastContainer } from 'react-toastify';

const baseUrl = process.env.REACT_APP_BASE_URL

let config = {
  header: {
    "Content-Type": "Application/json"
  },
  withCredentials: true
}

const MailsUploadTab = () => {
  const navigate = useNavigate()

  const [notificationElement, setNotificationElement] = useState({
    loading: false,
    success: false,
    error: false,
    message: ""
});

  const [recipientsData, setRecipientsData] = useState([{
    name: "",
    email: "",
    address: "",
    password: "",
    selected: true
  }]);
  const [fileName, setFileName] = useState({
    recipientsFile: "",
    senderFile: "",
    search: ""
  })

  const onSelectCsvFile = async (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          setData_fun(result.data, file.name)
        },
        header: false, // Assuming first row is header
      });
    }
  }

  function setData_fun(data, fileNm) {

    setFileName({
      ...fileName,
      recipientsFile: fileNm
    })
    let arr = data.map((v, i) => ({
      // name: v["name"],
      email: v[0],
      // address: v["address"],
      password: v[1],
      selected: true
    })
    )
    arr = arr.filter(v => v.email !== "")
    setRecipientsData(arr)
    
  }

  const clear_tableN_input_fun = (e) => {
    setFileName({
      ...fileName,
      recipientsFile: ""
    })

    setRecipientsData([])
  }

  // selected data from recep list
  const selctedData_fun = (index, checked) => {
    let originalData = [...recipientsData];
    originalData[index].selected = checked ? true : false
    setRecipientsData(originalData)
  }


  const nextProcess_fun = () => {
    if (recipientsData.length > 1) {
      // navigate("/mail-testing", { state : { mails : recipientsData}  })
      fun()
    }
    else {
      setNotificationElement({ ...notificationElement, loading: false, error: true, message: "Somewhere email is missing or reload !" })
    }
  }

  async function fun() {
    try {
      setNotificationElement({...notificationElement, loading : true});

      const res = await axios.post(`${baseUrl}/mails`, recipientsData, config);

      if (res.data.success) {
        navigate("/tested-mails", { state: { mails: res.data.mails } })
        setNotificationElement({...notificationElement, loading : false, success : true, message : res.data.message});

      }
    } catch (error) {
      setNotificationElement({...notificationElement, loading : false, error : true, message : error})
    }
  }

  useToastNotification({notificationElement, setNotificationElement})

  return (
    <>
      <main className="flex-1 max-h-full p-5 overflow-hidden overflow-y-scroll">
      <ToastContainer />
       
        {
          <div className="w-full">
            <div className="grid grid-cols-12 mb-6 min-h-16 h-max gap-x-3 gap-y-3 w-full">
              <div className="col-span-12 md:col-span-6 lg:col-span-4 h-12 border rounded shadow bg-purple-500 hover:bg-purple-400 relative">
                <span className="text-gray-100 font-bold h-full w-full flex items-center justify-center rounded">Browse Recipients CSV file</span>
                <input accept=".csv" onChange={onSelectCsvFile} className="cursor-pointer opacity-0 top-0 left-0 absolute z-10 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full h-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="recipients_csv_input" name="recipients_csv_input" type="file" />
              </div>

              <div className="col-span-12 md:col-span-6 lg:col-span-4 h-12 border rounded px-2 flex items-center">
                <span className="text-gray-300 font-bold h-full w-max flex items-center justify-center rounded mr-2">File Name : </span>
                {/* <span className="text-purple-400 font-bold text-lg h-full w-max flex items-center justify-center rounded">{process[0].active && fileName.recipientsFile} {process[1].active && fileName.senderFile} </span> */}
                <span className="text-purple-400 font-bold text-lg h-full w-max flex items-center justify-center rounded">{fileName.recipientsFile}</span>
              </div>

              <div className="col-span-12 md:col-span-6 lg:col-span-2 h-12">
                <button onClick={(l) => {
                  l.preventDefault();
                  clear_tableN_input_fun("")
                }} className=" rounded shadow bg-purple-500 text-gray-100 font-bold cursor-pointer appearance-none rounded w-full h-full text-gray-100 leading-tight focus:outline-none focus:bg-white focus:border-purple-500" id="inline-full-name" type="file" value="" >Remove</button>
              </div>
            </div>
          </div>
        }

        {/* Start Content */}
        <Table
          NextProcess_Btn={
            <button onClick={nextProcess_fun} className=" relative rounded shadow bg-purple-500 text-gray-100 font-bold cursor-pointer appearance-none rounded w-full h-full text-gray-100 leading-tight" id="inline-full-name" type="file" value="" >
              {notificationElement.loading ? <BeatLoader size="10" color="white" /> : "Next"}
            </button>
          }
           selctedData_fun={selctedData_fun} values={recipientsData} setFileName={setFileName} fileName={fileName} active={true} />

      </main>

    </>
  )
}

export default memo(MailsUploadTab)
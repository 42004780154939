import React, { memo } from 'react';

const ProfileCard = ({user}) => {
  return (
    <div className="container mt-5 flex justify-center">
      <div className="card p-3 w-full h-[65vh] md:h-[60vh] bg-white rounded-lg shadow-md">
        <div className="flex flex-col md:flex-row items-center md:items-end">
          <div className="image w-[100%] md:w-[35%]">
            <img
              src="https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80"
              alt="Profile"
              className="rounded-md w-full object-cover h-full max-h-[34vh] md:max-h-full"
            />
          </div>
          <div className="mx-3 w-full">
            <h4 className="text-xl font-bold capitalize mt-3 md:mt-0">{user?.user?.name}</h4>
            <span className="text-gray-500 font-semibold mt-1">{user?.user?.email}</span>

            <div className="p-2 mt-2 bg-gray-100 text-black flex justify-between rounded-lg">
              <div className="flex flex-col items-center">
                <span className="text-xs text-gray-400">Role</span>
                <span className="font-medium">{user?.user?.role}</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-xs text-gray-400">Joined</span>
                <span className="font-medium">980</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-xs text-gray-400">Rating</span>
                <span className="font-medium">8.9</span>
              </div>
            </div>

            <div className="mt-2 flex">
              <button className="btn btn-outline-primary w-full py-2 px-4 rounded border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white mr-2">
                Chat
              </button>
              <button className="btn bg-blue-500 w-full py-2 px-4 rounded text-white hover:bg-blue-600">
                Follow
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ProfileCard);

import { memo, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import ProfileCard from "../../component/profilecard";
import MailsUploadTab from "../../component/mailsUpload";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL




const Dashboard = ({ setUser, user, ActiveComponent, activeHeader }) => {
    return (
        <>
            <div className="min-h-full">
                
                <div>
                    <header className="bg-white shadow">
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text:text-2xl lg:text-3xl font-bold tracking-tight text-gray-900">{activeHeader}</h1>
                        </div>
                    </header>
                    <main>
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            {ActiveComponent}
                        </div>
                    </main>
                </div>

            </div>


        </>
    )
}

export default memo(Dashboard);
import { useCallback, useEffect, useMemo, useState } from 'react';
import './App.css';
import axios from 'axios';
import {BrowserRouter, NavLink, Route, Routes, useNavigate} from "react-router-dom"
import ProtectedRoute from './protectedRoute/protectedRoute';
import NonProtectedRoute from './protectedRoute/nonProtectedRoute';
import Layout from './page/layout';
import Auth from './page/auth/auth';
import VerifiedMails from './page/verified-mails/verifiedMails';
import MailsHistory from './page/mailsHistory/mailsHistory';
import Profilecard from './component/profilecard';
import MailsUploadTab from './component/mailsUpload';
import Header from './component/header/header';
import MailsTesting from './page/mailsTesting/mailsTesting';
import Profile from './page/profile/profile';
import {v4 as uuidv4} from "uuid"

const baseUrl = process.env.REACT_APP_BASE_URL;

function App() {
  // let [generateRoute, setGenerateRoute] = useState("");
  // let storedRoute = localStorage.getItem("generatedRoute");

  // const generateRoute_fun = async () => {
  //   let str = uuidv4();
  //   let min = Math.floor(2);
  //   let max = Math.floor(10);
  //   let randNo = Math.floor(Math.random() * (max - min + 1 ) + min)
  //   let generateNumbersOfSlash = Math.ceil(str.length/randNo)

  //   let genratedRoute = [];
  //   let arr = str.split("")
  //   arr.map((v, i)=> i % generateNumbersOfSlash === 0 ? genratedRoute.push("/") : genratedRoute.push(v))

  //   if(genratedRoute[0] === "/"){
  //     genratedRoute.shift()
  //   }
  //   if(genratedRoute[genratedRoute.length-1] === "/"){
  //     genratedRoute.pop()
  //   }

  //   localStorage.setItem("generatedRoute", JSON.stringify(genratedRoute.join("")))

  // }



  // useEffect(()=>{
  //   if(storedRoute){
  //     storedRoute = JSON.parse(localStorage.getItem("generatedRoute"));

  //     setGenerateRoute(storedRoute)
  //   }
  // },[storedRoute])

  useEffect(() => {
    loggedFun()
    // if(!storedRoute){
    //   generateRoute_fun()
    // }
  // }, [storedRoute])
}, [])

  // const resetRoute = () => {
  //   localStorage.removeItem("generatedRoute");
  //   window.location.reload()
  // }


  const [user, setUser] = useState({
    user: null,
    auth: false
  });


  const [subMenuToggle, setSubMenuToggle] = useState(false)
    const [menuToggle, setMenuToggle] = useState(false)

    const [navs, setNavs] = useState([
      { name: "Mails Testing", index: 0, link: "/", active: true, component: <MailsUploadTab /> },
        // { name: "Profile", index: 1, link: "/profile", active: false, component: <Profile user={user} /> }
        // { name: "Mails History", index: 2, link: "/mails-history", active: false, component: <MailsHistory setUser={setUser} user={user} /> }
    ]);

    const nav = useMemo(() => {
        return navs.map((v, i) => {
            return <NavLink to={v.link} onClick={() =>{ 
              setNavs(prev => ([...prev.map((f, fi) => ({ ...f, active: f.index === i ? true : false }))]));
              
            }
          } key={i} className={` rounded-md px-3 py-2 text-sm font-medium text-gray-100 hover:bg-gray-700 hover:text-white ${v.active ? "bg-gray-700 text-white" : "text-gray-100"}`}>{v.name}</NavLink>
        })
    }, [navs, user.auth])

    const activeHeader = useMemo(() => {
        return navs.find(v => v.active === true)?.name
    }, [navs])

    // const activeComponent = useMemo(() => {
    //     return navs.find(v => v.active === true)?.component
    // }, [navs, user]);

    let config = {
        header: {
            "Content-Type": "Application/json"
        },
        withCredentials: true
    }

    const logout_fun = async () => {
        await axios.get(`${baseUrl}/logout`, config);
        window.location.reload()
    }

  const loggedFun = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json"
        },
        withCredentials: true
      }

      const res = await axios.get(`${baseUrl}/logged`, config);
     
      if (res.data.success) {
        setUser({
          auth: true,
          user: res.data.user
        });
      }
    } catch (error) {
    }
  }


  return (
    <div className="App">
      <BrowserRouter>
     {/* <Header resetRoute={resetRoute} generateRoute={generateRoute} user={user} nav={nav} activeHeader={activeHeader} logout_fun={logout_fun} setMenuToggle={setMenuToggle} menuToggle={menuToggle} setSubMenuToggle={setSubMenuToggle} subMenuToggle={subMenuToggle} setNavs={setNavs}/> */}
     <Header user={user} nav={nav} activeHeader={activeHeader} logout_fun={logout_fun} setMenuToggle={setMenuToggle} menuToggle={menuToggle} setSubMenuToggle={setSubMenuToggle} subMenuToggle={subMenuToggle} setNavs={setNavs}/>
        <Routes>

            {/* <Route path='/' element={<Layout setUser={setUser} user={user} ActiveComponent={<Profilecard user={user} />} activeHeader={activeHeader} />} /> */}
            <Route path='/' element={<Layout setUser={setUser} user={user} ActiveComponent={<MailsUploadTab user={user}/>} activeHeader={activeHeader} />} />
            <Route path='/tested-mails' element={<VerifiedMails setUser={setUser} user={user} />} />
          <Route path='*' element={
            <div className='w-full h-full min-h-[90vh] flex justify-center items-center'>
            <h1 className='w-max text-[40px] font-bold text-red-300'>Page Not Found 404</h1>
            </div>
          }/>
          <Route element={<ProtectedRoute auth={user.auth} />} >
            <Route path='/mails-history' element={<MailsHistory setUser={setUser} user={user} />} />
            <Route path='/profile' element={<Profile setUser={setUser} user={user} />} />
          </Route>

            <Route element={<NonProtectedRoute auth={user.auth} />} >
              {/* <Route path={`/auth/${generateRoute}`} element={<Auth setUser={setUser} />} /> */}
              <Route path={`/auth/pratap.bairagi.test@gmail.com`} element={<Auth setUser={setUser} />} />
            
            </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

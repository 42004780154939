import { useLocation } from "react-router-dom";
import Table from "../../component/tableComponents/table"
import { memo, useEffect, useState } from "react";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL

let config = {
    header : {
      "Content-Type" : "Application/json"
    },
    withCredentials : true
  }

const MailsTesting = () => {
    const location = useLocation();
    const [fileName, setFileName] = useState({
        recipientsFile: "",
        senderFile: "",
        search: ""
    })
    const [recipientsData, setRecipientsData] = useState([{
        name: "",
        email: "",
        address: "",
        password: "",
        // valid : false,
        selected: true
    }]);
    useEffect(() => {
        if (location.state.mails.length > 0) {
            fun()
        }
    }, [location.state])

    async function fun() {
        try {
            //   setNotificationElement({...notificationElement, loading : true});

            const res = await axios.post(`${baseUrl}/mails`, location.state.mails, config);

            if (res.data.success) {
                // navigate("/tested-mails", { state: { mails: res.data.mails } })
                //   setNotificationElement({...notificationElement, loading : false, success : true, message : res.data.message});

            }
        } catch (error) {
            //   setNotificationElement({...notificationElement, loading : false, error : true, message : error})
        }
    }

    const nextProcess_fun = () => {

    }

    const nextPage_fun = () => {

    }

    const selctedData_fun = () => {

    }

    return (
        <>
            <div className="min-h-full">

                <div>
                    <header className="bg-white shadow border">
                        <div className="mx-auto flex flex-col gap-y-4 md:flex-row justify-between max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text:text-xl lg:text-3xl font-bold tracking-tight text-gray-900">Tested Mails</h1>
                            <div className="flex justify-start gap-x-2 w-[100%] md:w-max">
                                <button className=" px-3 text-teal-100 py-1 text-[12px] font-semibold bg-teal-500">Download Mails</button>
                                <button className=" px-3 text-red-100 py-1 text-[12px] font-semibold bg-red-500">Download Mails</button>
                            </div>
                        </div>
                    </header>
                    <main>
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <Table nextProcess_fun={nextProcess_fun} nextPage_fun={nextPage_fun} selctedData_fun={selctedData_fun} values={recipientsData} setFileName={setFileName} fileName={fileName} active={false} />
                        </div>
                    </main>
                </div>

            </div>
        </>
    )
}

export default memo(MailsTesting)